const SuggestionForm = {
	how_improve: 'How can we improve?',
	title: 'Title',
	title_placeholder: 'A summary about your suggestion',
	description: 'Description',
	description_placeholder: 'Go into more detail about your idea',
	name_and_email: 'Name & Email (optional)',
	name_placeholder: 'Name',
	email_placeholder: 'Email',
	optional_information: `The two fields above are completely optional. 
    If you do choose to enter your information, you can rest assure that we won't have access to your email and it will only live on our servers. 
    Name will be displayed under the suggestion, email is used to let you know when there are any updates about your suggestion.
  `,
  submit: 'Submit',
  labels: 'Labels (Optional)',
  image: 'Image (Optional)',
  select_label: 'Select a label'
}

export default SuggestionForm;