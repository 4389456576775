import * as SiteActions from './../actions/SiteActions';

const initialState = {
  site: {}
};

const SiteReducer = (state = initialState, action) => {
  switch (action.type) {
    case SiteActions.SET_SITE:
      return {
        ...state,
        site: action.site
      };
    default:
      return state;
  }
};

export default SiteReducer;