import { combineReducers } from 'redux';
import SessionReducer from './SessionReducer';
import UserReducer from './UserReducer';
import ModalReducer from './ModalReducer';
import SiteReducer from './SiteReducer';

const RootReducer = combineReducers({
	session: SessionReducer,
	site: SiteReducer,
	user: UserReducer,
	modals: ModalReducer
});

export default RootReducer;
