import * as ModalActions from './../actions/ModalActions';

const initialState = {
  show_user_modal: false,
  show_first_suggestion_modal: false
};

const ModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case ModalActions.USER_SESSION_MODAL_TOGGLE:
      return {
        ...state,
        show_user_modal: action.show_user_modal
      };
    case ModalActions.FIRST_SUGGESTION_MODAL_TOGGLE:
      return {
        ...state,
        show_first_suggestion_modal: action.show_first_suggestion_modal
      };
    default:
      return state;
  }
};

export default ModalReducer;