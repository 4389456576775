import { Suspense, lazy } from 'react';

import * as routeMapping from './routeMapping';

const MainPage = lazy(() => import('./pages/MainPage'));
const NewSuggestionPage = lazy(() => import('./pages/suggestions/NewSuggestionPage'));
const SuggestionPage = lazy(() => import('./pages/suggestions/SuggestionPage'));
const SearchSuggestionPage = lazy(() => import('./pages/suggestions/SearchSuggestionPage'));


const LoginPage = lazy(() => import('./pages/authentication/LoginPage'));
const ForgotPasswordPage = lazy(() => import('./pages/authentication/ForgotPasswordPage'));
const DashboardPage = lazy(() => import('./pages/administrative/DashboardPage'));
const AccountPage = lazy(() => import('./pages/administrative/AccountPage'));
const PageSettingsPage = lazy(() => import('./pages/administrative/PageSettingsPage'));
const UsersPage = lazy(() => import('./pages/administrative/UsersPage'));
const TagsPage = lazy(() => import('./pages/administrative/TagsPage'));

const SetupSitePage = lazy(() => import('./pages/setup/SetupSitePage'));
const SetupAdministratorPage = lazy(() => import('./pages/setup/SetupAdministratorPage'));
const SetupPaymentPage = lazy(() => import('./pages/setup/SetupPaymentPage'));
const SetupPasswordPage = lazy(() => import('./pages/setup/SetupPasswordPage'));

const routes = [
  {
    path: routeMapping.MAIN_PAGE,
    component: MainPage,
    exact: true
  },
  {
    path: routeMapping.NEW_SUGESTION_PAGE,
    component: NewSuggestionPage,
    exact: true
  },
  {
    path: routeMapping.SUGESTION_PAGE,
    component: SuggestionPage,
    exact: true
  },
  {
    path: routeMapping.SEARCH_SUGESTION_PAGE,
    component: SearchSuggestionPage,
    exact: true
  },
  {
    path: routeMapping.LOGIN_PAGE,
    component: LoginPage,
    exact: true
  },
  {
    path: routeMapping.FORGOT_PASSWORD_PAGE,
    component: ForgotPasswordPage,
    exact: true
  },
  {
    path: routeMapping.DASHBOARD_PAGE,
    component: DashboardPage,
    exact: true
  },
  {
    path: routeMapping.ACCOUNT_PAGE,
    component: AccountPage,
    exact: true
  },
  {
    path: routeMapping.PAGE_SETTINGS_PAGE,
    component: PageSettingsPage,
    exact: true
  },
  {
    path: routeMapping.ADMINISTRATIVE_USERS_PAGE,
    component: UsersPage,
    exact: true
  },
  {
    path: routeMapping.TAGS_PAGE,
    component: TagsPage,
    exact: true
  },
  {
    path: routeMapping.SETUP_SITE_PAGE,
    component: SetupSitePage,
    exact: true
  },
  {
    path: routeMapping.SETUP_ADMINISTRATOR_PAGE,
    component: SetupAdministratorPage,
    exact: true
  },
  {
    path: routeMapping.SETUP_PAYMENT_PAGE,
    component: SetupPaymentPage,
    exact: true
  },
  {
    path: routeMapping.SETUP_PASSWORD,
    component: SetupPasswordPage,
    exact: true
  }
];

export default routes;