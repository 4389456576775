import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import ConfigureStore from './stores/ConfigureStore';
import { loadState, saveState } from './stores/LocalStorage';

import App from './App';
import routes from './routes';

const preloadedState = loadState();

const store = ConfigureStore(
	preloadedState, 
	(window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
);

store.subscribe(() => {
	saveState(store.getState());
})

const Updater = (props) => (
	<Provider store={store}>
		<BrowserRouter>
			<App 
				routes={routes}
				railsProps={props}
			/>
		</BrowserRouter>
	</Provider>
);

render(
	<Updater/>
	, window.document.querySelector('#root')
);

