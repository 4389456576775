import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import './UserLoginForm.scss';
import { useForm } from "react-hook-form";
import TextArea from './../../components/designSystem/TextArea';
import Input from './../../components/designSystem/Input';
import Button from './../../components/designSystem/Button';

import * as UserAPI from './../../api/UserAPI';
import * as userActions from './../../actions/UserActions';
import * as modalActions from './../../actions/ModalActions';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function UserForgotPasswordForm(props){  
  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  const onSubmit = data => {
    submitData(data);
  };

  function submitData(data){
    UserAPI.forgotPassword(data).then(res => {
      toast.success('Check your email to reset you password', {});        
      props.setUserModal(false);
    }).catch((error) => {
      toast.warn('Email or Password are wrong', {});      
    });
  }

  return (
    <div id="user-login--form">
      <div className="user-login--form--container">
        <h1>Forgot password</h1>        
        <p>
          Enter your email below and we’ll send you instructions on how to reset your password.
        </p>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="user-login--form--input-line">
            <Input 
              label="Email"
              register={register}
              name='email'
              type='email'
              required={true}
            />
          </div>
          <div className="user-login--form-actions">
            <Button className="primary-color high-button full-width with-shadow">
              Submit
            </Button>
          </div>
        </form>

        <div className="user-login--form-signup" onClick={() => {props.changeStatus(0)}}>
          Back to <b>Log in</b>
        </div>
      </div>
      <ToastContainer />
    </div>
  )  
}

const mapStateToProps = state => {
  return {
    currentUser: state.session.current_user,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    onLogin: (data) => dispatch(userActions.userUpdate(data)),    
    setUserModal: (data) => dispatch(modalActions.userSessionModalToggle(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserForgotPasswordForm);