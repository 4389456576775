export const USER_SESSION_MODAL_TOGGLE = 'USER_SESSION_MODAL_TOGGLE';
export const FIRST_SUGGESTION_MODAL_TOGGLE = 'FIRST_SUGGESTION_MODAL_TOGGLE';

export const userSessionModalToggle = (status) => {
  return {
    type: USER_SESSION_MODAL_TOGGLE,
    show_user_modal: status
  };
};

export const firstSuggestionModalToggle = (status) => {
  return {
    type: FIRST_SUGGESTION_MODAL_TOGGLE,
    show_first_suggestion_modal: status
  };
};