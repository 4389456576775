export const ADMIN_LOGIN = 'ADMIN_LOGIN';
export const ADMIN_LOGOUT = 'ADMIN_LOGOUT';

export const adminLogin = (admin) => {
  return {
    type: ADMIN_LOGIN,
    current_admin: admin
  };
};

export const adminLogout = () => {
  return {
    type: ADMIN_LOGOUT
  };
};