import MainPage from './pt/MainPage';
import SuggestionMenu from './pt/SuggestionMenu';
import SuggestionForm from './pt/SuggestionForm';
import SuggestionCard from './pt/SuggestionCard';
import CommentsThread from './pt/CommentsThread';
import CommentsForm from './pt/CommentsForm';

const pt = {
	MainPage: MainPage,
	CommentsForm: CommentsForm,
	CommentsThread: CommentsThread,
	SuggestionMenu: SuggestionMenu,
	SuggestionForm: SuggestionForm,
	SuggestionCard: SuggestionCard
}


export default pt;