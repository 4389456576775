import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import RootReducer from './../reducers/RootReducer';

const ConfigureStore = (preloadedState, devtools = null) => {
  let ext = {};

  if(devtools === null){
    ext = applyMiddleware(thunk)
  }
  else{
    ext = compose(
      applyMiddleware(thunk),
      devtools
    )
  }

  const store = createStore(
    RootReducer,
    preloadedState,
    ext
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers/RootReducer', () => {
      const nextRootReducer = require('../reducers/RootReducer').default;
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
};

export default ConfigureStore;


