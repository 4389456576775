import React from 'react';
import './TextArea.scss';

class TextArea extends React.Component {
  state = {
    id: Math.random().toString(36).substr(2, 9),
    selected: false
  }

  constructor(props) {
    super(props);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);

    if(this.props.id != null){
      this.setState({ id: this.props.id });
    }
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  componentDidMount(){
    document?.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document?.removeEventListener('mousedown', this.handleClickOutside);
  }

  selectField(){
    this.setState({selected: true});
  }

  handleClickOutside(){
    this.setState({selected: false});    
  }

  render() {
    return (        
      <div className={
        'textarea-input ' + (this.state.selected ? ' selected ' : ' ') + 
        this.props.className
      } 
        onClick={this.selectField.bind(this)}
        ref={this.setWrapperRef}
      >
        <textarea 
          className='input-field'
          rows="5"
          onChange={this.props.onChange}
          defaultValue={this.props.value}
          placeholder={this.props.placeholder}
          required={this.props.required}

          {... (this.props.register ? this.props.register(this.props.name) : null)}
        />
        <label>
          {this.props.label}
        </label>
      </div>
    )  
  }
}


export default TextArea;