import axios from '../axios-config';

export const getSite = (subdomain) => {
	var subdomain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;
	var options = {
		method: 'get',
		params: {},
		headers: {
			'Content-Type': 'application/json',
			'Subdomain': subdomain
		},
		json: true
	};

	return new Promise((resolve, reject) => {
		axios(`/sites/${subdomain}`, options)
		.then(response => {
			resolve(response['data']);
		})
		.catch( error => {
		});
	})
}

export const createSite = (data, authorization_token) => {
	var subdomain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;
	var options = {
		method: 'post',
		params: {},
		headers: {
			'Content-Type': 'application/json',
			'Authorization': authorization_token,
			'Subdomain': subdomain
		},
		data: data,
		json: true
	};

	return new Promise((resolve, reject) => {
		axios(`/sites`, options)
		.then(response => {
			resolve(response['data']);
		})
		.catch(error => {
			reject(error);
		});
	})
}

export const updateSite = (data, authorization_token, subdomain) => {
	var subdomain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;
	var options = {
		method: 'put',
		params: {},
		headers: {
			'Content-Type': 'application/json',
			'Authorization': authorization_token,
			'Subdomain': subdomain
		},
		data: data,
		json: true
	};

	return new Promise((resolve, reject) => {
		axios(`/sites/${subdomain}`, options)
		.then(response => {
			resolve(response['data']);
		})
		.catch(error => {
			reject(error);
		});
	});
}

export const setupAdmin = (url, data) => {
	var subdomain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;
	var options = {
		method: 'post',
		params: {},
		headers: {
			'Content-Type': 'application/json',
			'Subdomain': subdomain
		},
		data: data,
		json: true
	};

	return new Promise((resolve, reject) => {
		axios(`/sites/${url}/setup_admin`, options)
		.then(response => {
			resolve(response['data']);
		})
		.catch(error => {
			reject(error);
		});
	})
}


export const setupAdminWithGoogle = (url, data) => {
	var subdomain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;
	var options = {
		method: 'post',
		params: {},
		headers: {
			'Content-Type': 'application/json',
			'Subdomain': subdomain
		},
		data: data,
		json: true
	};

	return new Promise((resolve, reject) => {
		axios(`/sites/${url}/setup_admin_with_google`, options)
		.then(response => {
			resolve(response['data']);
		})
		.catch(error => {
			reject(error);
		});
	})
}

export const setupPayment = (data, authorization_token, url) => {
	var subdomain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;
	var options = {
		method: 'post',
		params: {},
		headers: {
			'Content-Type': 'application/json',
			'Authorization': authorization_token,
			'Subdomain': subdomain
		},
		data: data,
		json: true
	};

	return new Promise((resolve, reject) => {
		axios(`/sites/${url}/setup_payment`, options)
		.then(response => {
			resolve(response['data']);
		})
		.catch(error => {
			reject(error);
		});
	})
}
