const SuggestionForm = {
	how_improve: 'Como podemos melhorar?',
	title: 'Título',
	title_placeholder: 'Resumo sobre sua sugestão',
	description: 'Descrição',
	description_placeholder: 'Informe mais detalhes sobre seu problema',
	name_and_email: 'Nome & Email (optional)',
	name_placeholder: 'Nome',
	email_placeholder: 'Email',
	optional_information: `Os dois campos acima são completamente opcionais. Se você escolher infroma-las elas seram utilizadas somente para notifica-lo quando sua sugestão passar por alguma alteração.
  `,
  submit: 'Enviar',
  labels: 'Categorias (Opcional)',
  image: 'Imagem (Opcional)',
  select_label: 'Selecione quais categorias faz parte'
}

export default SuggestionForm;