import axios from '../axios-config';

export const createUser = (data) => {
	var subdomain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;
	var options = {
		method: 'post',
		data: data,
		params: {},
		headers: {
			'Content-Type': 'application/json',
			'Subdomain': subdomain
		},
		json: true
	};

	return new Promise((resolve, reject) => {
		axios(`/users`, options)
		.then(response => {
			resolve(response['data']);
		})
		.catch(error => {
			reject(error);
		});
	})
}

export const forgotPassword = (data) => {
	var subdomain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;
	var options = {
		method: 'post',
		data: data,
		params: {},
		headers: {
			'Content-Type': 'application/json',
			'Subdomain': subdomain
		},
		json: true
	};

	return new Promise((resolve, reject) => {
		axios(`/users/forgot_password`, options)
		.then(response => {
			resolve(response['data']);
		})
		.catch(error => {
			reject(error);
		});
	})
}

export const postSession = (auth) => {
	var subdomain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;
	var options = {
		method: 'post',
		data: auth,
		params: {},
		headers: {
			'Content-Type': 'application/json',
			'Subdomain': subdomain
		},
		json: true
	};

	return new Promise((resolve, reject) => {
		axios(`/users/create_users_session`, options)
		.then(response => {
			resolve(response['data']);
		})
		.catch(error => {
			reject(error);
		});
	})
}

export const postSessionWithGoogle = (auth) => {
	var subdomain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;
	var options = {
		method: 'post',
		data: auth,
		params: {},
		headers: {
			'Content-Type': 'application/json',
			'Subdomain': subdomain
		},
		json: true
	};

	return new Promise((resolve, reject) => {
		axios(`/users/create_users_session_with_google`, options)
		.then(response => {
			resolve(response['data']);
		})
		.catch(error => {
			reject(error);
		});
	})
}

export const recoverPasswordSession = (auth) => {
	var subdomain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;
	var options = {
		method: 'post',
		data: auth,
		params: {},
		headers: {
			'Content-Type': 'application/json',
			'Subdomain': subdomain
		},
		json: true
	};

	return new Promise((resolve, reject) => {
		axios(`/users/recover_users_password`, options)
		.then(response => {
			resolve(response['data']);
		})
		.catch(error => {
			reject(error);
		});
	})
}