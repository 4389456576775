export const MAIN_PAGE = '/';
export const NEW_SUGESTION_PAGE = '/new';
export const SEARCH_SUGESTION_PAGE = '/search';
export const SUGESTION_PAGE = '/suggestion/:id';

export const LOGIN_PAGE = '/login';
export const FORGOT_PASSWORD_PAGE = '/forgot_password';

export const DASHBOARD_PAGE = '/administrative/dashboard';
export const PAGE_SETTINGS_PAGE = '/administrative/page_settings';
export const ADMINISTRATIVE_USERS_PAGE = '/administrative/administrative_users';
export const ACCOUNT_PAGE = '/administrative/account';
export const TAGS_PAGE = '/administrative/tags';

export const SETUP_SITE_PAGE = '/setup/site';
export const SETUP_ADMINISTRATOR_PAGE = '/setup/administrator';
export const SETUP_PAYMENT_PAGE = '/setup/payment';
export const SETUP_PASSWORD = '/setup/password';


