import MainPage from './en/MainPage';
import SuggestionMenu from './en/SuggestionMenu';
import SuggestionForm from './en/SuggestionForm';
import SuggestionCard from './en/SuggestionCard';
import CommentsForm from './en/CommentsForm';
import CommentsThread from './en/CommentsThread';

const en = {
	MainPage: MainPage,
	CommentsForm: CommentsForm,
	CommentsThread: CommentsThread,
	SuggestionMenu: SuggestionMenu,
	SuggestionForm: SuggestionForm,
	SuggestionCard: SuggestionCard
}

export default en;