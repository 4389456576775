import React, { Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { createI18n, I18nProvider } from 'react-simple-i18n';
import langData from './i18n/i18n';

import * as SiteAPI from './api/SitesAPI';
import * as siteActions from './actions/SiteActions';
import * as userActions from './actions/UserActions';

import UserSessionModal from './components/users/UserSessionModal';
import FirstSuggestionModal from './components/suggestions/FirstSuggestionModal';

import loading from './global/animations/loading.json';
import Lottie from 'react-lottie';
import {v4 as uuidv4} from 'uuid';

import ReactGA from 'react-ga';
import './App.scss';

class App extends React.Component {
	constructor(props){
		super(props);
		this.setDomainData();
		this.setGoogleAnalytics();
	}

	setGoogleAnalytics(){
  	const trackingId = "UA-210966527-1";

		ReactGA.initialize(trackingId);
		ReactGA.pageview(this.props.history.location.pathname);

		this.props.history.listen((location, action) => {
		  ReactGA.pageview(location.pathname);
		});
	}

	setDomainData(){
		var subdomain =  window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;

		if(subdomain){
			if(subdomain != 'app'){
				SiteAPI.getSite(subdomain).then(res => {
					this.props.setSite(res['data']['attributes']);
					document.title = `${this.props.site.name} | Product Gears`;
				});
			}
		}
		else{
			window.location.href = 'https://app.productgears.com/setup/administrator';
		}
	}

	render() {
		return (
      <I18nProvider i18n={
        createI18n(langData, { 
          lang: this.props.site.language
        })
      }>
      	<Suspense fallback={
      		<div className="loading--page">
      			<Lottie options={{
      					loop: true,
					      autoplay: true, 
					      animationData: loading,
					      rendererSettings: {
					        preserveAspectRatio: 'xMidYMid slice'
					      }
      				}}
              height={400}
              width={400}
              isStopped={false}
              isPaused={false}
            />
      		</div>
      	}>
					<Switch>
						{this.props.routes.map((route, index) => {
							return (
								<Route
									key={index}
									path={route.path}
									exact={route.exact}
									render={props =>
										React.createElement(route.component, {
											...props
										})}
								/>
							);
						})}
					</Switch>
					<FirstSuggestionModal />
					<UserSessionModal/>
				</Suspense>
			</I18nProvider>
		);
	}
}

const mapStateToProps = state => {
  return {
    site: state.site.site,
    current_user: state.user.current_user
  };
}

const mapDispatchToProps = dispatch => {
  return {
    setSite: (data) => dispatch(siteActions.setSite(data)),
    setUser: (data) => dispatch(userActions.userUpdate(data))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));