import * as SessionActions from './../actions/SessionActions';

const initialState = {
  current_admin: {}
};

const SessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SessionActions.ADMIN_LOGIN:
      return {
        ...state,
        current_admin: action.current_admin
      };
    case SessionActions.ADMIN_LOGOUT:
      return {
        ...state,
        current_admin: {}
      };
    default:
      return state;
  }
};

export default SessionReducer;