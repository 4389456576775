import React from 'react';
import './Input.scss';

class Input extends React.Component {
  state = {
    selected: false,
    withContent: false
  }

  constructor(props) {
    super(props);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.checkForContent = this.checkForContent.bind(this)
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef(node) {
    this.wrapperRef = node;
    this.checkForContent();
  }

  componentDidMount(){
    document?.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document?.removeEventListener('mousedown', this.handleClickOutside);
  }

  checkForContent(){
    if(this.props?.value != '' && this.props?.value != undefined){
      this.setState({withContent: true});
    }
    else{
      this.setState({withContent: false});
    }
  }

  selectField(){
    this.setState({selected: true});
  }

  handleClickOutside(){
    this.setState({selected: false});    
  }

  handleKeyUp(e){
    let target = e.target;

    if(target.value != ''){
      this.setState({withContent: true});
    }
    else{
      this.setState({withContent: false});
    }
  }

  getClassesName(){
    let names = '';

    if(this.state.selected){
      names = names + ' selected';
    }

    if(this.props.disabled){
      names = names + ' disabled';
    }

    if(this.state.withContent){
      names = names + ' with-content';
    }

    return names;
  }

  render() {
    return (        
      <div className={
        this.props.className + ' common-input ' + (this.getClassesName())
      } 
        onClick={this.selectField.bind(this)}
        ref={this.setWrapperRef}
      >
        <input 
          className='input-field'
          type={this.props.type ? this.props.type : 'text'}
          onChange={this.props.onChange}     
          onKeyUp={this.handleKeyUp.bind(this)}
          onFocus={this.selectField.bind(this)}
          onBlur={this.handleClickOutside.bind(this)}
          defaultValue={this.props.value}
          required={this.props.required}
          disabled={this.props.disabled}
          placeholder={this.props.placeholder}

          {... (this.props.register ? this.props.register(this.props.name) : null)}
        />
        <label>
          {this.props.label}
        </label>

        <div className={"icon " + (this.props.icon ? '' : 'hide')}>
          <img src={this.props.icon}/>
        </div>
      </div>
    )  
  }
}


export default Input;