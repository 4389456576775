import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { motion, AnimatePresence } from "framer-motion";
import { withI18n } from 'react-simple-i18n';
import Button from './../designSystem/Button';
import './FirstSuggestionModal.scss';
import { withRouter } from 'react-router-dom';

import * as modalActions from './../../actions/ModalActions';


function FirstSuggestionModal(props){
	return (
		<AnimatePresence>
	    {props.modalState && (
	    	<motion.div className="first-suggestion-modal"
	    		initial={{ opacity: 0 }}
	        animate={{ opacity: 1 }}
	        exit={{ opacity: 0 }}
  				transition={{ duration: 0.2 }}
	    	>
	    		<div className="first-suggestion-modal--background"
	    			onClick={() => {props.setModal(false)}}
	    		></div>
	    		<motion.div className="first-suggestion-modal--card"	    		
		    		initial={{ opacity: 0, y: '25%' }}
		        animate={{ opacity: 1, y: 0, }}
		        exit={{ opacity: 0, y: '25%' }}
	  				transition={{ duration: 0.2 }}
	    		>	
	    			<iframe src="https://giphy.com/embed/3oEjI5VtIhHvK37WYo" width="480" height="270" frameBorder="0" className="giphy-embed" allowFullScreen></iframe>
	    			<h1>Thank you 🎉</h1>

	    			<div className="first-suggestion-modal--actions">
		    			<Button className="secondary-color with-shadow high-button stretched"
		    				onClick={() => {props.setModal(false)}}
		    			>
		    			  View first suggestion
		    			</Button>
		    			<Button className="primary-color with-shadow high-button stretched"
		    				onClick={() => {props.history.push("/new"); props.setModal(false)}}
		    			>
		    			  Add another suggestion
		    			</Button>

	    			</div>
	    		</motion.div>
	    	</motion.div>
    	)}
  	</AnimatePresence>
	)  
}

const mapStateToProps = state => {
  return {
    modalState: state.modals.show_first_suggestion_modal,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    setModal: (data) => dispatch(modalActions.firstSuggestionModalToggle(data))
  }
}

export default withRouter(withI18n(connect(mapStateToProps, mapDispatchToProps)(FirstSuggestionModal)));