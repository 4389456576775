import * as UserActions from './../actions/UserActions';

const initialState = {
  current_user: {}
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case UserActions.USER_UPDATE:
      return {
        ...state,
        current_user: action.current_user
      };
    default:
      return state;
  }
};

export default UserReducer;