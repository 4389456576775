import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withI18n } from 'react-simple-i18n';
import { motion, AnimatePresence } from "framer-motion";

import UserLoginForm from './UserLoginForm';
import UserSignupForm from './UserSignupForm';
import UserForgotPasswordForm from './UserForgotPasswordForm';

import * as modalActions from './../../actions/ModalActions';

import './UserSessionModal.scss';

function UserSessionModal(props){
  const {t} = props;

  //
  // 0 = Login
  // 1 = Signup
  // 2 = Forgot Password
  //
  const [status, setStatus] = useState(0);

  return (
    <AnimatePresence>
      { props.modalState == true && (
        <motion.div id="user-login-modal"        
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}       
        >            
          <div className="user-login-modal--background"
            onClick={() => {props.setUserModal(false)}}
          ></div>
          <motion.div        
            initial={{ opacity: 0, y: '25%' }}
            animate={{ opacity: 1, y: '0%', }}
            exit={{ opacity: 0, y: '25%' }}
            transition={{ duration: 0.3 }}
            style={{height: '100vh', width: '1px', position: 'fixed', left: '50%'}}    
          > 
            { status == 0 && ( <UserLoginForm changeStatus={setStatus} /> )}
            { status == 1 && ( <UserSignupForm changeStatus={setStatus} />)}
            { status == 2 && ( <UserForgotPasswordForm changeStatus={setStatus} />)}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

const mapStateToProps = state => {
  return {
    modalState: state.modals.show_user_modal,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    setUserModal: (data) => dispatch(modalActions.userSessionModalToggle(data))
  }
}

export default withI18n(connect(mapStateToProps, mapDispatchToProps)(UserSessionModal));