import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import './UserLoginForm.scss';
import { useForm } from "react-hook-form";
import TextArea from './../../components/designSystem/TextArea';
import Input from './../../components/designSystem/Input';
import Button from './../../components/designSystem/Button';

import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { ReactSVG } from 'react-svg';

import * as UserAPI from './../../api/UserAPI';
import * as userActions from './../../actions/UserActions';
import * as modalActions from './../../actions/ModalActions';

import GoogleIcon from './../../global/icons/google.svg';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const firebaseConfig = {
  apiKey: "AIzaSyC2y5uIfCY0SCiJo6Iv5PGxiOBwxQmKju0",
  authDomain: "onbuddy-ce880.firebaseapp.com",
  projectId: "onbuddy-ce880",
  storageBucket: "onbuddy-ce880.appspot.com",
  messagingSenderId: "1095329147532",
  appId: "1:1095329147532:web:e0d508851d36390ffbe9a9",
  measurementId: "G-N6357HDXL1"
};

function UserSignupForm(props){  
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const firebase = initializeApp(firebaseConfig);
  const auth = getAuth();

  const onSubmit = data => {
    submitData(data);
  };

  function submitData(data){
    UserAPI.createUser(data).then(res => {
      let user = res.data.attributes;
      props.onLogin(user);
      props.setUserModal(false);
    }).catch((error) => {
      toast.warn('Email or Password are wrong', {});      
    });
  }

  function loginWithGoogle(){
    const provider = new GoogleAuthProvider();

    signInWithPopup(auth, provider)
    .then((result) => {
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      const user = result.user;

      UserAPI.postSessionWithGoogle({access_token: token}).then(res => {
        let user = res.data.attributes;
        props.onLogin(user);
        props.setUserModal(false);
      })
    }).catch((error) => {
      console.log(error)
    });
  }

  return (
    <div id="user-login--form">
      <div className="user-login--form--container">
        <h1>Create your account</h1>

        <Button className="google-button high-button full-width with-shadow" 
          onClick={loginWithGoogle}>
          <ReactSVG 
            src={GoogleIcon}
          />
          Sign up with Google
        </Button>
        
        <p>
          or use your email to sign up
        </p>

        <form onSubmit={handleSubmit(onSubmit)}>          
          <div className="user-login--form--input-line">
            <Input 
              label="Name"
              register={register}
              name='name'
              type='text'
              required={true}
            />
          </div>

          <div className="user-login--form--input-line">
            <Input 
              label="Email"
              register={register}
              name='email'
              type='email'
              required={true}
            />
          </div>

          <div className="user-login--form--input-line">
            <Input 
              label="Password"
              register={register}
              name='password'
              type='password'
              required={true}
            />
          </div>
          <div className="user-login--form-forgot-password" onClick={() => {props.changeStatus(2)}}>
            Forgot Password?
          </div>
          <div className="user-login--form-actions">
            <Button className="primary-color high-button full-width with-shadow">
              Signup
            </Button>
          </div>
        </form>

        <div className="user-login--form-signup" onClick={() => {props.changeStatus(0)}}>
           Already have an account? <b>Log In</b>
        </div>
      </div>
      <ToastContainer />
    </div>
  )  
}

const mapStateToProps = state => {
  return {
    currentUser: state.session.current_user,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    onLogin: (data) => dispatch(userActions.userUpdate(data)),    
    setUserModal: (data) => dispatch(modalActions.userSessionModalToggle(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSignupForm);