import React from 'react';
import {Link} from 'react-router-dom';
import './Button.scss';

const Button = props => {
	if(props.withLink){
		return (
		  <Link 
		    className={'button-component link-component ' + props.className}
		    to={props.withLink}
		  >
		    {props.children}
		  </Link>
		);
	}
	else{
		return (
		  <button 
		    className={'button-component ' + props.className}
		    onClick={props.onClick}
		  >
		    {props.children}
		  </button>
		);
	}
};

export default Button;




